<template>
  <view-item title="维修领料">
    <template #operation>
      <button-ajax
        v-if="(form.data.id && form.data.serviceMaterialStatus?.code === 'U') || form.data.serviceMaterialStatus?.code === 'I'"
        :disabled="form.disabled || form.loading"
        :method="operation.submit.click"
      >
        领料
      </button-ajax>

      <en-button type="primary" :disabled="form.disabled || form.loading" @click="operation.returnMaterial.click">退料</en-button>

      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="transfer">配件转移</en-dropdown-item>
          <en-dropdown-item command="details">领料明细</en-dropdown-item>
          <en-dropdown-item
            v-if="
              store.accessRightsHash.MATERIAL_REQUISITION_EXTERNAL_COMPLETE &&
              form.data.status?.code &&
              ['IM', 'MC'].includes(form.data.status.code) &&
              form.data.quick?.code === 'N'
            "
            command="finish"
          >
            {{ form.data.serviceMaterialStatus?.code === 'C' ? '取消完毕' : '完毕' }}
          </en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        code="SVCMRFTFD"
        :ajax="{
          action: 'GET /enocloud/service/complex',
          params: (params) => {
            params.payload = {
              material: true,
              rsexp: 'id,serialNo,customer[name],vehicle[plateNo],serviceMaterialStatus'
            }
          }
        }"
        :props="{ start: 'preparedStartDate', end: 'preparedEndDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
        :addition="{ serviceMaterialStatusCode: ['U', 'I'] }"
        :ref="setRef('manifest')"
      >
        <template #form="{ data }">
          <en-form-item label="工单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="领料状态">
            <select-maintain
              v-model="data.serviceMaterialStatusCode"
              multiple
              :ajax="{
                action: 'GET /enocloud/common/lookup/:lookupType',
                params: (params) => (params.paths = ['SVCMTRSTAT'])
              }"
              :props="{ label: 'message', value: 'code' }"
              value-key="code"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="车品牌">
            <en-input v-model="data.vehicleSpec"></en-input>
          </en-form-item>
          <en-form-item label="服务顾问">
            <select-maintain
              v-model="data.advisorId"
              :ajax="{ action: 'GET /enocloud/common/advisorteam/advisor' }"
              :props="{ label: 'user.name', value: 'user.id' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="维修项目">
            <select-maintain
              v-model="data.maintenance"
              :ajax="{ action: 'GET /enocloud/common/maintenance', params: (params, value) => (params.payload = { name: value }) }"
              :props="{ label: 'name', value: 'name' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="编码">
            <en-input v-model="data.goodsSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="OE号">
            <en-input v-model="data.oem"></en-input>
          </en-form-item>
          <en-form-item label="配件名称">
            <en-input v-model="data.goodsName"></en-input>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
          <en-form-item label="适用车型">
            <en-input v-model="data.goodsVehicleSpec"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <div class="flex gap-4">
          <span>维修类别：{{ form.data.serviceCategory?.name }}</span>
          <span>服务顾问：{{ form.data.advisor?.name }}</span>
        </div>

        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data.vehicle?.plateNo }}</span>
                <span>{{ form.data.customer?.name }}/{{ form.data.customer?.cellphone }}</span>
                <span>单号：{{ form.data.serialNo }}</span>
                <en-tag>{{ form.data.serviceMaterialStatus?.message }}</en-tag>
                <span>{{ form.data.vehicle?.vehicleSpec.join('/') }}</span>
                <span class="text-red-500">{{ formatMoney(form.data.customer?.accountInfo.debt) }}(欠款)</span>
                <span>{{ form.data.status?.message }}</span>
              </div>
            </template>
            <div class="grid grid-cols-4 gap-x-6">
              <span>结算台次：{{ form.data.vehicle?.consumeCount }}</span>
              <span>客户分类：{{ form.data.customer?.category?.name }}</span>
              <span>VIN码：{{ form.data.vehicle?.vin }}</span>
              <span>工单类别：{{ form.data.quick?.value ? '快速工单' : '普通工单' }}</span>
              <span>进厂时间： {{ formatDate(form.data.enterDatetime, true) }}</span>
              <span>发动机号：{{ form.data.vehicle?.engineNumber }}</span>
              <span>工单介绍人： {{ form.data.salesman?.name }}</span>
              <span>预计完工： {{ formatDate(form.data.expectedCompletionDatetime, true) }}</span>
              <span>变速箱： {{ form.data.vehicle?.gearboxType?.message }}</span>
              <span>送修人：{{ form.data.senderTelephone }}</span>
              <span>接车备注： {{ form.data.comment }}</span>
              <span>领料备注： {{ form.data.materialRequisitionComment }}</span>
            </div>
          </en-collapse-item>
        </en-collapse>

        <div class="flex items-center gap-6 text-xs text-neutral-400">
          <div class="flex flex-col gap-2">
            <span>已领配件金额</span>
            <span>{{ formatMoney(form.summary.materialAmount) }}</span>
          </div>
          <div class="flex flex-col gap-2">
            <span>已领配件进价</span>
            <span>{{ formatMoney(form.summary.materialAmount) }}</span>
          </div>
          <div class="flex flex-col gap-2">
            <span>已领数量</span>
            <span>{{ formatMoney(form.summary.materialAmount) }}</span>
          </div>
          <div class="flex flex-col gap-2">
            <span>领料数量</span>
            <span>{{ formatMoney(form.summary.materialAmount) }}</span>
          </div>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="配件"
                name="goods"
                :badge="form.data.goods?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <select-maintain
                    :ajax="{
                      action: 'GET /enocloud/inventory',
                      params: (params, value) =>
                        (params.payload = {
                          inMaterialOnly: 'Y',
                          quickSeatch: value,
                          rsexp: 'id,goods[id,name,serialNo,batches[*],specifications[*],warehouseUnit]'
                        })
                    }"
                    :props="{
                      label: 'goodsName',
                      value: '',
                      disabled: (option: EnocloudInventoryDefinitions['InventoryDto']) =>
                        Boolean(form.data.goods?.find((item) => item.goods?.id === option.id))
                    }"
                    value-key="id"
                    placeholder="请选择配件"
                    remote
                    class="w-110"
                    :disabled="form.disabled"
                    @change="form.goods.operation.change"
                  >
                    <template #default="{ option }: { option: EnocloudInventoryDefinitions['InventoryDto'] }">
                      <div class="flex items-center justify-between gap-5">
                        <span>{{ option.goods?.name }}</span>
                        <div class="flex text-xs text-[#8492a6] gap-5">
                          <span>编码：{{ option.goods?.serialNo }}</span>
                          <span
                            >库存: {{ option.goods?.batches.reduce((count, item) => calculator.add(count, item.count), 0)
                            }}{{ option.goods?.warehouseUnit }}</span
                          >
                        </div>
                      </div>
                    </template>
                  </select-maintain>
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic
                      code="MRQE"
                      :data="form.data.goods"
                      :height="height"
                      :config="form.goods.config"
                      sortable
                      @header-click="form.goods.header.click"
                      @sort-click="form.goods.sort.click"
                    >
                      <template #OPERATION="{ row, $index }: { row: EnocloudServiceDefinitions['ServiceGoodsDto']; $index: number }">
                        <en-button type="primary" link :disabled="form.disabled || row.count > 0" @click="form.goods.operation.delete.click($index)">
                          {{ row.count && row.returnedServiceGoods?.id ? '撤销退货' : '删除' }}
                        </en-button>
                      </template>

                      <template #REQUISITION_COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <en-input-number
                          v-model="row.materialCount"
                          :min="0"
                          :precision="2"
                          :max="row.inventory?.count ?? 0"
                          :disabled="form.disabled"
                          class="w-full"
                        ></en-input-number>
                      </template>

                      <template #PRICE="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        {{ formatMoney(row.price) }}
                      </template>

                      <template #DISCOUNT_RATE="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <en-input-rate v-model="row.discountRate" class="w-full"></en-input-rate>
                      </template>

                      <template #GOODS_UNIT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span v-if="form.disabled">{{ row.goodsSpecification?.name }}</span>
                        <select-maintain
                          v-else
                          :model-value="row.goodsSpecification"
                          :options="row.goodsSpecification?.goods?.specifications"
                          :props="{ label: 'name', value: '' }"
                          value-key="id"
                          :disabled="row.count > 0 || !row.goodsSpecification?.id || !!row.returnedServiceGoods?.id"
                          @change="form.goods.goodsSpecification.change(row, $event)"
                          class="w-full"
                        ></select-maintain>
                      </template>

                      <template #PICKED_BY="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span v-if="form.disabled">{{ row.pickedBy }}</span>
                        <select-maintain
                          v-else
                          v-model="row.pickedBy"
                          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['MTRPKR']) }"
                          :props="{ label: 'name', value: 'name' }"
                          filterable
                          class="w-full"
                        ></select-maintain>
                      </template>

                      <template #WORKING_TEAM="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span v-if="form.disabled">{{ row.workingTeam?.name }}</span>
                        <select-maintain
                          v-else
                          v-model="row.workingTeam"
                          :ajax="{
                            action: 'GET /enocloud/common/workingteam',
                            params: (params, name) => (params.payload = { name, branchIds: form.data.branch?.id ? [form.data.branch.id] : [] })
                          }"
                          :props="{ label: 'name', value: '' }"
                          value-key="id"
                          remote
                          class="w-full"
                        ></select-maintain>
                      </template>

                      <template #CHARGING_METHOD="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span v-if="form.disabled">{{ row.workingTeam?.name }}</span>
                        <select-maintain
                          v-else
                          v-model="row.chargingMethod"
                          :ajax="{
                            action: 'GET /enocloud/common/lookup/:lookupType',
                            params: (params) => (params.paths = ['CHGMTD'])
                          }"
                          :props="{
                            label: 'message',
                            value: '',
                            disabled: (option: EnocloudCommonDefinitions['LookupDto']) => ['RWK'].includes(option.code)
                          }"
                          value-key="code"
                          class="w-full"
                        ></select-maintain>
                      </template>

                      <template #BATCH="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span v-if="form.disabled">{{ row.inventory?.batchNo }}</span>
                        <select-maintain
                          v-else
                          v-model="row.inventory"
                          :options="row.goodsSpecification?.goods?.batches"
                          :props="{ label: (option) => option.batchNo || option.warehouse.name, value: '' }"
                          value-key="id"
                          class="w-full"
                        ></select-maintain>
                      </template>

                      <template #COMMENT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span v-if="form.disabled">{{ row.inventory?.batchNo }}</span>
                        <en-input v-else v-model="row.comment" class="w-full"></en-input>
                      </template>

                      <template #SALESMAN="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span v-if="form.disabled">{{ row.salesman?.name }}</span>
                        <select-maintain
                          v-else
                          v-model="row.salesman"
                          :ajax="{
                            action: 'GET /enocloud/common/user',
                            params: (params, name) => (params.payload = { name, status: 'A' })
                          }"
                          :props="{ label: 'name', value: '' }"
                          value-key="id"
                          remote
                          class="w-full"
                        ></select-maintain>
                      </template>

                      <template #FAKE_COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span>{{ row.fakeCount }}</span>
                      </template>

                      <template #REQUISITION_FAKE_COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <en-input-number
                          v-model="row.materialFakeCount"
                          :min="0"
                          :disabled="form.disabled || !!row.returnedServiceGoods?.id"
                          class="w-full"
                        ></en-input-number>
                      </template>

                      <template #RECEIVED_COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <en-tooltip :content="`增领${row.fakeCount}个`">
                          <span :style="{ color: row.fakeCount ? '#f4523b' : '' }">
                            {{ row.returnedServiceGoods?.id ? 0 - (row.returnedServiceGoods?.returnedCount ?? 0) : row.count }}
                          </span>
                        </en-tooltip>
                      </template>

                      <template #INVENTORY_COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span>{{ `${row.inventory?.count ?? 0 / (row.goodsSpecification?.weight ?? 1)}${row.goodsSpecification?.name}` }}</span>
                      </template>

                      <template #PRIME_COST="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span>{{ formatMoney(row.inventory?.primeCost) }}</span>
                      </template>

                      <template #PREPARED_BY="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
                        <span>{{ [...row.histories].pop()?.preparedBy?.name }}</span>
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <inventory-requestion-dialog v-model="dialog.visible"></inventory-requestion-dialog>

  <en-drawer v-model="returnMaterial.visible" title="退料" direction="btt" size="50%">
    <en-form>
      <en-form-item label="退料备注">
        <en-input type="textarea" v-model="returnMaterial.data.materialRequisitionComment"></en-input>
      </en-form-item>
    </en-form>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="returnMaterial.goodsData" :height="height" :loading="returnMaterial.loading">
          <en-table-column label="OE号" prop="goodsSpecification.goods.oem"></en-table-column>
          <en-table-column label="编码" prop="goodsSpecification.goods.serialNo" width="170"></en-table-column>
          <en-table-column label="名称" prop="goodsSpecification.goods.name"></en-table-column>
          <en-table-column label="单位 " prop="goodsSpecification.name"></en-table-column>
          <en-table-column label="领/退料班组" prop="workingTeam.name"></en-table-column>
          <en-table-column label="领/退料人" prop="pickedBy"></en-table-column>
          <en-table-column label="退料数量">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
              <en-input-number v-model="row.materialCount" :min="0" :max="row.count - row.fakeCount" :precision="2" class="w-full"></en-input-number>
            </template>
          </en-table-column>
          <en-table-column label="已领数量">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">{{ formatNumber(row.count) }}</template>
          </en-table-column>
          <en-table-column label="增领">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">
              <en-input-number
                v-model="row.materialFakeCount"
                :min="0"
                :max="row.fakeCount"
                :precision="2"
                :disabled="!row.fakeCount"
                class="w-full"
              ></en-input-number>
            </template>
          </en-table-column>
          <en-table-column label="已增领">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">{{ formatNumber(row.fakeCount) }}</template>
          </en-table-column>
          <en-table-column label="仓库" prop="inventory.warehouse.name"></en-table-column>
          <en-table-column label="进价" v-if="store.accessRightsHash.PRICE_PURCHASE">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsDto'] }">{{ formatNumber(row.cost) }}</template>
          </en-table-column>
          <en-table-column label="批次" prop="inventory.batchNo" width="150"></en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button :disabled="returnMaterial.loading" @click="returnMaterial.footer.cancel.click">取消</en-button>
      <button-ajax :disabled="returnMaterial.loading" :method="returnMaterial.footer.confirm.click">退料</button-ajax>
    </template>
  </en-drawer>

  <en-dialog v-model="transfer.visible" title="配件转移" width="80%">
    <en-form inline label-position="left">
      <en-form-item label="配件转移" required>
        <select-maintain
          v-model="transfer.serviceId"
          :ajax="{
            action: 'GET /enocloud/service/complex',
            params: (params, quickSearch) => {
              params.payload = {
                quickSearch,
                excludedServiceId: form.data.id,
                notComplete: true,
                rsexp: 'id,serialNo,customer[name],vehicle[plateNo],serviceMaterialStatus'
              }
            }
          }"
          :props="{ label: 'serialNo', value: '' }"
          clearable
          value-key="id"
          class="w-full"
        >
          <template #default="{ option }">
            <div class="flex justify-between">
              <span>{{ option.vehicle && option.vehicle.plateNo }}</span>
              <span>{{ option.customer && option.customer.name }}</span>
              <span>{{ option.serialNo }}</span>
            </div>
          </template>
        </select-maintain>
      </en-form-item>
    </en-form>

    <en-table :data="transfer.goodsData" :loading="transfer.loading" @selection-change="transfer.goods.selection.change">
      <en-table-column type="selection"></en-table-column>
      <en-table-column label="名称" prop="goods.name"></en-table-column>
      <en-table-column label="编码" prop="goods.serialNo"></en-table-column>
      <en-table-column label="OE号" prop="goods.oem"></en-table-column>
      <en-table-column label="规格" prop="goods.specifications[0].name"></en-table-column>
      <en-table-column label="数量" prop="count"></en-table-column>
      <en-table-column label="进价" prop="cost" v-if="store.accessRightsHash.PRICE_PURCHASE"></en-table-column>
      <en-table-column label="维修单价" prop="amountBeforeDiscount"></en-table-column>
      <en-table-column label="金额" prop="amount"></en-table-column>
      <en-table-column label="收费类别" prop="chargingMethod.message"></en-table-column>
      <en-table-column label="领/退料班组" prop="histories[0].workingTeam.name"></en-table-column>
      <en-table-column label="领/退料人" prop="histories[0].pickedBy"></en-table-column>
      <en-table-column label="批次" prop="inventory.batchNo"></en-table-column>
      <en-table-column label="增领" prop="fakeCount"></en-table-column>
    </en-table>

    <template #footer>
      <en-button :disabled="transfer.loading" @click="transfer.footer.cancel.click">取消</en-button>
      <button-ajax :disabled="transfer.loading" :method="transfer.footer.confirm.click">确定</button-ajax>
    </template>
  </en-dialog>

  <en-dialog v-model="details.visible" title="领料明细" width="80%">
    <table-dynamic code="SVSGIHFLD" :data="details.data" :method="details.get" :paging="details.paging" pagination :loading="details.loading">
      <template #GOODS_NAME="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ row.serviceGoods?.goodsSpecification?.goods?.name }}
      </template>

      <template #GOODS_SERIAL_NO="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ row.serviceGoods?.goodsSpecification?.goods?.serialNo }}
      </template>

      <template #GOODS_OEM="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ row.serviceGoods?.goodsSpecification?.goods?.oem }}
      </template>

      <template #GOODS_UNIT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ row.serviceGoods?.goodsSpecification?.name }}
      </template>

      <template #COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ formatNumber(0 - (row.inventoryHistory?.count ?? 0), 2) }}
      </template>

      <template #FAKE_COUNT="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ formatNumber(row.fakeCount) }}
      </template>

      <template #PRIME_COST="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ formatMoney(row.inventoryHistory?.inventory.primeCost) }}
      </template>

      <template #SERVICE_GOODS_PRICE="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ formatMoney(row.serviceGoods?.price) }}
      </template>

      <template #REQUISITION_TYPE="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ row.inventoryHistory?.type?.message }}
      </template>

      <template #CHARGING_METHOD="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        <select-maintain
          :model-value="row.serviceGoods?.chargingMethod"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => {
              params.paths = ['CHGMTD']
              params.payload = { excludes: ['RWK'] }
            }
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="row.serviceMaterialRequisition?.type?.code === 'MRQR'"
        ></select-maintain>
      </template>

      <template #WORKING_TEAM="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        <select-maintain
          v-model="row.workingTeam"
          :ajax="{
            action: 'GET /enocloud/common/workingteam',
            params: (params, name) => {
              params.payload = { name, branchIds: form.data.branch?.id ? [form.data.branch.id] : [] }
            }
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
        ></select-maintain>
      </template>

      <template #PICKED_BY="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        <select-maintain
          v-model="row.pickedBy"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['MTRPKR']) }"
          :props="{ label: 'name', value: 'name' }"
        ></select-maintain>
      </template>

      <template #BATCH="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ row.inventoryHistory?.inventory?.batchNo }}
      </template>

      <template #PICKED_DATETIME="{ row }: { row: EnocloudServiceDefinitions['ServiceGoodsInventoryHistoryDto'] }">
        {{ formatDate(row.inventoryHistory?.datetime, true) }}
      </template>
    </table-dynamic>

    <template #footer>
      <en-button :disabled="details.loading" @click="details.footer.cancel.click">取消</en-button>
      <button-ajax :disabled="details.loading" :method="details.footer.confirm.click">确定</button-ajax>
    </template>
  </en-dialog>

  <en-dialog v-model="setting.visible" title="批量设置" width="30%">
    <en-form :model="setting.form.data" :rules="setting.form.rules" label-position="left" :ref="setRef('settingForm')">
      <en-form-item v-if="setting.form.type === 'workingTeam'" label="领/退料班组" prop="workingTeam.id">
        <select-maintain
          v-model="setting.form.data.workingTeam"
          :ajax="{
            action: 'GET /enocloud/common/workingteam',
            params: (params, name) => (params.payload = { name, branchIds: [form.data.branch?.id] })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
        ></select-maintain>
      </en-form-item>

      <en-form-item v-if="setting.form.type === 'pickedBy'" label="领/退料人" prop="pickedBy">
        <select-maintain
          v-model="setting.form.data.pickedBy"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['MTRPKR'])
          }"
          :props="{ label: 'name', value: 'name' }"
          value-key="id"
        ></select-maintain>
      </en-form-item>

      <en-form-item v-if="setting.form.type === 'requestionCount'" label="领/退料数量" prop="requestionCount">
        <en-input-number v-model="setting.form.data.requestionCount"></en-input-number>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button type="primary" @click="setting.footer.confirm.click">确定</en-button>
    </template>
  </en-dialog>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'
import { EnMessage, EnMessageBox, type TableColumn, type TableSortCtx } from '@enocloud/components'

import InventoryRequestionDialog from '@accessory/components/inventory-requestion-dialog.vue'

const formDataGoodsInit = (props: Partial<EnocloudServiceDefinitions['ServiceGoodsDto']>) => {
  return Object.assign(
    { inflatedFlag: { code: 'N', type: '', description: '', message: '' }, histories: [] },
    props
  ) as EnocloudServiceDefinitions['ServiceGoodsDto']
}

const settingFormInit = () => {
  return {
    workingTeam: {} as EnocloudServiceDefinitions['WorkingTeamDto'],
    pickedBy: '',
    requestionCount: 0
  }
}

export default factory({
  page: true,

  components: { InventoryRequestionDialog },

  config: {
    children: {
      operation: {
        submit: {
          async click() {
            const execute = async () => {
              try {
                const res = await EnMessageBox.confirm('确认领料', '提示')
                if (res !== 'confirm') return
                await this.form.submit()
                this.refs.manifest.table.method()
                return this.form.get()
              } catch (err) {}
            }

            const { goods = [] } = this.form.data
            const materialGoods = goods.filter((item) => item.materialCount > 0)

            const noPickedGoodsName = materialGoods
              .filter((item) => !item.pickedBy)
              .map((item) => item.name)
              .join('/')
            if (noPickedGoodsName.length) return EnMessage.warning(`${noPickedGoodsName}未填写领/退料人`)

            const noBatchGoodsName = materialGoods
              .filter((item) => !item.inventory?.id)
              .map((item) => item.name)
              .join('/')

            if (noBatchGoodsName.length) return EnMessage.warning(`${noBatchGoodsName}未选择批次/仓库`)

            const hasLowPriceGoodsName = materialGoods
              .filter((item) => item.price < (item.inventory?.primeCost ?? 0))
              .map((item) => item.name)
              .join('/')

            if (hasLowPriceGoodsName.length) {
              try {
                const res = await EnMessageBox({ title: '温馨提示', message: `${hasLowPriceGoodsName}配件领料出库单价低于进价，是否要继续领料?` })
                if (res === 'confirm') return execute()
              } catch (err) {
                return
              }
            }

            return execute()
          }
        },
        returnMaterial: {
          click() {
            this.returnMaterial.init()
            this.returnMaterial.data.id = this.form.data.id
            this.returnMaterial.get()
            this.returnMaterial.visible = true
          }
        },
        option: {
          async command(option: string) {
            switch (option) {
              case 'transfer':
                this.transfer.init()
                this.transfer.data.id = this.form.data.id
                this.transfer.get()
                this.transfer.visible = true
                break
              case 'details':
                this.details.data = []
                this.details.get()
                this.details.visible = true
                break
              case 'finish':
                await this.form.finish()
                this.form.get()
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudServiceDefinitions['ServiceDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'goods'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            },
            convert(data: EnocloudServiceDefinitions['ServiceDto']) {
              const { goods = [], serviceMaterialStatus } = data
              data.goods = goods.map((item) => {
                if (serviceMaterialStatus?.code === 'U') {
                  if (item.returnedServiceGoods?.id) item.materialCount = 0
                  else {
                    const count = calculator.sub(item.planCount, item.count)
                    item.materialCount = count > 0 ? count : 0
                  }
                }

                item.inventory ??= item.goodsSpecification?.goods?.batches[0] ?? { count: 0 }
                return item
              })
              return data
            }
          },
          submit: {
            action: 'PUT /enocloud/service/material',
            loading: true,
            params(params) {
              const res = Object.assign({}, this.form.data)

              for (const item of res.goods ?? []) {
                item.returnedServiceGoods ??= {
                  amount: 0,
                  amountBeforeDiscount: 0,
                  count: 0,
                  discountRate: 0,
                  fakeCount: 0,
                  histories: [],
                  materialCount: 0,
                  materialFakeCount: 0,
                  planCount: 0,
                  price: 0,
                  returnedCount: 0
                }
              }

              params.payload = res
            }
          },
          finish: {
            action: 'PUT /enocloud/service/material/status',
            loading: true,
            params(params) {
              const res = Object.assign({}, this.form.data)
              res.serviceMaterialStatus ??= { code: '', type: '', message: '', description: '' }
              res.serviceMaterialStatus.code = ['U', 'I'].includes(res.serviceMaterialStatus.code) ? 'C' : 'I'
              params.payload = res
            }
          }
        },
        rules: {},
        computed: {
          disabled() {
            return this.form.data.serviceMaterialStatus?.code === 'C'
          },
          summary() {
            return {
              materialAmount: 0,
              materialCost: 0
            }
          }
        },
        children: {
          goods: {
            config: {
              WORKING_TEAM: {
                header: {
                  clickable: true
                }
              },
              PICKED_BY: {
                header: {
                  clickable: true
                }
              },
              REQUISITION_COUNT: {
                header: {
                  clickable: true
                }
              }
            },
            header: {
              click(column: TableColumn) {
                if (!this.form.data.goods?.length) {
                  return EnMessage.warning('请选择配件')
                }
                switch (column.columnName?.code) {
                  case 'WORKING_TEAM':
                    this.setting.form.type = 'workingTeam'
                    this.setting.visible = true
                    break
                  case 'PICKED_BY':
                    this.setting.form.type = 'pickedBy'
                    this.setting.visible = true
                    break
                  case 'REQUISITION_COUNT':
                    this.setting.form.type = 'requestionCount'
                    this.setting.visible = true
                    break
                }
              }
            },
            sort: {
              click(ctx: TableSortCtx) {
                const { column, order } = ctx
                switch (column.columnName?.code) {
                  case 'PICKED_BY':
                    const data = this.form.data.goods?.sort((cur, nex) => (cur.pickedBy ?? '').localeCompare(nex.pickedBy ?? '')) ?? []
                    this.form.data.goods = order === 'descending' ? data : data.reverse()
                    break
                }
              }
            },
            operation: {
              change(value: EnocloudInventoryDefinitions['InventoryDto']) {
                this.form.data.goods ??= []

                const { specifications = [], batches } = value.goods!

                const goodsSpecification = Object.assign({}, specifications.find((item) => item.defaultService?.value) ?? specifications[0], {
                  goods: value.goods
                }) as EnocloudServiceDefinitions['GoodsSpecificationDto']

                const chargingMethod =
                  this.form.data.serviceCategory?.type?.code === 'REWORK'
                    ? { code: 'CLM', message: '索赔', type: '', description: '' }
                    : this.form.data.serviceCategory?.type?.code === 'ACCIDENT'
                    ? { code: 'INS', message: '一般保险', type: '', description: '' }
                    : { code: 'CLT', message: '自费维修', type: '', description: '' }

                const discountRate = this.form.data.customer?.serviceGoodsDiscountRate ?? 0

                const price = goodsSpecification.servicePrice

                const init = formDataGoodsInit({
                  goodsSpecification,
                  chargingMethod,
                  discountRate,
                  price,
                  inventory: batches?.[0] ?? {}
                })

                this.form.data.goods.push(init)
              },
              add: {
                click() {}
              },
              delete: {
                click(index: number) {
                  this.form.data.goods?.splice(index, 1)
                }
              }
            },
            count: {
              change(row: EnocloudServiceDefinitions['ServiceGoodsDto']) {}
            },
            goodsSpecification: {
              change(row: EnocloudServiceDefinitions['ServiceGoodsDto'], value: EnocloudServiceDefinitions['GoodsSpecificationDto']) {
                const goods = Object.assign({}, row.goodsSpecification?.goods)
                row.goodsSpecification = value
                row.goodsSpecification.goods = goods
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      returnMaterial: {
        visible: false,
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.returnMaterial.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/service/material',
            loading: true,
            params(params) {
              const res = Object.assign({}, this.returnMaterial.data)

              for (const item of res.goods ?? []) {
                item.returnedServiceGoods ??= {
                  amount: 0,
                  amountBeforeDiscount: 0,
                  count: 0,
                  discountRate: 0,
                  fakeCount: 0,
                  histories: [],
                  materialCount: 0,
                  materialFakeCount: 0,
                  planCount: 0,
                  price: 0,
                  returnedCount: 0
                }

                item.materialCount = item.materialCount ? 0 - item.materialCount : item.materialCount
                item.materialFakeCount = item.materialFakeCount ? 0 - item.materialFakeCount : item.materialFakeCount
              }

              params.payload = res
            }
          }
        },
        computed: {
          goodsData() {
            return this.returnMaterial.data.goods?.filter((item) => !item.returnFinished?.value && item.count > 0)
          }
        },
        children: {
          footer: {
            cancel: {
              click() {
                this.returnMaterial.visible = false
              }
            },
            confirm: {
              async click() {
                await this.returnMaterial.submit()
                this.returnMaterial.visible = false
                return this.form.get()
              }
            }
          }
        }
      },
      transfer: {
        visible: false,
        serviceId: '',
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.transfer.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/service/:serviceId/material/transfer',
            loading: true,
            params(params) {
              params.paths = [this.transfer]
              params.data = this.transfer.goods.selection.data.map((item) => item.id)
            }
          }
        },
        computed: {
          goodsData() {
            return this.transfer.data.goods?.filter((item) => !item.returnFinished?.value && item.count > 0)
          }
        },
        children: {
          goods: {
            selection: {
              data: [] as EnocloudServiceDefinitions['ServiceGoodsDto'][],
              change(rows: EnocloudServiceDefinitions['ServiceGoodsDto'][]) {
                this.transfer.goods.selection.data = rows
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.transfer.visible = false
              }
            },
            confirm: {
              async click() {
                if (!this.transfer.serviceId) return EnMessage.warning('请选择转移工单')
                if (!this.transfer.goods.selection.data.length) return EnMessage.warning('请选择转移配件')
                await this.transfer.submit()
                this.transfer.visible = false
                return this.form.get()
              }
            }
          }
        }
      },
      details: {
        visible: false,
        ajax: {
          get: {
            action: 'GET /enocloud/service/material/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { serviceSerialNo: this.form.data.serialNo }
            }
          },
          submit: {
            action: 'PUT /enocloud/service/material/history',
            loading: true
          }
        },
        children: {
          footer: {
            cancel: {
              click() {
                this.details.visible = false
              }
            },
            confirm: {
              async click() {
                this.details.visible = false
                return this.form.get()
              }
            }
          }
        }
      },
      setting: {
        visible: false,
        title: '',
        children: {
          form: {
            type: '' as '' | 'workingTeam' | 'pickedBy' | 'requestionCount',
            rules: {
              'workingTeam.id': { required: true, message: '请选择服务顾问' },
              pickedBy: { required: true, message: '请选择领/退料人' },
              requestionCount: { required: true, message: '请填写领料数量' }
            },
            data: settingFormInit()
          },
          footer: {
            confirm: {
              async click() {
                await this.refs.settingForm.validate()
                const { workingTeam, pickedBy, requestionCount } = this.setting.form.data
                switch (this.setting.form.type) {
                  case 'workingTeam':
                    for (const item of this.form.data.goods ?? []) {
                      item.workingTeam = workingTeam
                    }
                    break
                  case 'pickedBy':
                    for (const item of this.form.data.goods ?? []) {
                      item.pickedBy = pickedBy
                    }
                    break
                  case 'requestionCount':
                    for (const item of this.form.data.goods ?? []) {
                      item.materialCount = requestionCount > (item.inventory?.count ?? 0) ? item.inventory?.count ?? 0 : requestionCount
                    }
                    break
                }
                this.setting.visible = false
                this.setting.title = ''
                this.setting.form.type = ''
                this.setting.form.data = settingFormInit()
              }
            }
          }
        }
      }
    }
  }
})
</script>
